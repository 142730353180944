<template>
    <div>
        <sidenav
            :is-sidebar-active.sync="isSidebarActive"
            :item-edit.sync="itemEdit"
            :is-add.sync="isAdd"
            @refetch-data="refetchData"
            :tenant-options="tenantOptions"
        />

        <Breadcrumb
          :nameNav="nameNav"
          :itemFilter="itemFilter"
          :searchQuery="searchQuery"
          @itemFilterSearch="addItemFilterSearch"
        />

        <b-card class="m-2" no-body>
            <div class="p-2">
                <b-row>
                    <!-- Per Page -->
                    <b-col
                        cols="12"
                        md="6"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <label class="text-capitalize">{{ $t("table.show") }} </label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50"
                        />
                        <label class="text-capitalize">{{ $t("table.entries") }} </label>
                    </b-col>

                    <!-- Search -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                :placeholder="$t('table.search')"
                            />
                            <b-button
                                variant="primary"
                                @click="openAdd()"
                                v-if="$can('create', 'Sector')"
                            >
                                <span class="text-nowrap text-capitalize">
                                    {{ $t('safr.addGroup')}}
                                </span>
                            </b-button>
                        </div>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refListTable"
                class="position-relative"
                :items="fetchList"
                responsive
                :fields="tableColumns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                :empty-text="$t('no_record_found')"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #head()="data">
                    <span> {{ $tc(data.label, 1) }}</span>
                </template>

                <template #head(name)="data">
                    <span> {{ $tc(data.label, 2) }}</span>
                </template>

                <template #head(enabled)="data">
                    <span> {{ $tc(data.label, 2) }}</span>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-button
                        :id="`actions-${data.item.id}`"
                        tabindex="0"
                        variant="white"
                        class="px-1 btn-y"
                    >
                        <feather-icon
                            icon="MoreVerticalIcon"
                            size="16"
                            class="align-middle text-body"
                        />
                    </b-button>
                    <b-popover
                        :target="`actions-${data.item.id}`"
                        triggers="focus"
                        placement="bottom"
                        custom-class="popover-p-0"
                        variant="white"
                    >
                        <b-list-group class="m-0">
                            <b-list-group-item
                                v-if="$can('delete', 'PersonList')"
                                @click="confirmDelete(data.item.id)"
                            >
                                <feather-icon icon="TrashIcon" />
                                <span class="align-middle ml-50 text-capitalize">
                                    {{$t("actions.delete")}}
                                </span>
                            </b-list-group-item>
                        </b-list-group>
                    </b-popover>
                </template>
            </b-table>
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                      cols="12"
                      sm="6"
                      class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted text-capitalize">
                            {{ $t('table.showing', {from: dataMeta.from,to: dataMeta.to,of: dataMeta.of}) }}
                        </span>
                    </b-col>
                  <!-- Pagination -->
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalList"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
    </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  // BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref,computed } from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
//import Filters from "./Filters.vue";
import axios from '@/libs/axios'
import { useUtils as useI18nUtils } from '@core/libs/i18n'
import Breadcrumb from '@/components/Breadcrumb.vue'

export default {
    components: {
        Sidenav,
        //Filters,
        Breadcrumb,

        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect
    },

  setup(_, context) {
      const isSidebarActive = ref(false)
      const itemEdit = ref({})
      const isAdd = ref(false)

      const openEdit = (item) => {
          isSidebarActive.value = true
          itemEdit.value        = item
          isAdd.value           = false
      }
      const { t } = useI18nUtils()

      const {
          fetchList,
          tableColumns,
          perPage,
          currentPage,
          totalList,
          dataMeta,
          perPageOptions,
          searchQuery,
          sortBy,
          isSortDirDesc,
          refListTable,
          refetchData,
          formatDateAssigned,

          tenantOptions,
          tenantFilter,
          contentToast
      } = useList()

      const confirmDelete = function (name) {
          const self = this;

          this.$swal({
              title: context.parent.$i18n.t("messages.delete.title"),
              text: context.parent.$i18n.t("messages.delete.body"),
              icon: "warning",
              showCancelButton: true,
              confirmButtonText: context.parent.$i18n.t("actions.delete"),
              cancelButtonText: context.parent.$i18n.t("actions.cancel"),
              customClass: {
                  confirmButton: "btn btn-primary text-capitalize",
                  cancelButton: "btn btn-outline-danger ml-1 text-capitalize",
              },
              buttonsStyling: false,
          }).then((result) => {
              if (result.value) {
                  deleteGroup(name)
              }
        })
    }

    const deleteGroup = async (id) => {
        let { data } = await axios.delete(`/v1/services/facial-recognition/safr/groups/${id}`)
        let { message,success } = data

        if (!success) {
            contentToast('No se pudo eliminar el grupo','danger')
        } else {
            contentToast(message,'success')
            refetchData()
        }
    }

    const openAdd = () => {
        isAdd.value = true
        isSidebarActive.value = true
    }

    const itemFilter = ref();
    const addItemFilter = (data) => {
      itemFilter.value = data;
    };
    const addItemFilterSearch = (data) => {
      data.forEach((element) => {
        if (nameNav.value === element.text) {
          searchQuery.value = element.searchQuery;
        }
      });
    };
    const nameNav = ref("breadcrumbs.vass.analytics.facial_recognition.sarf.events");

    return {
        // Sidebar
        isSidebarActive,
        isAdd,
        itemEdit,
        openEdit,
        openAdd,
        confirmDelete,

        //Breadcrumb
        itemFilter,
        addItemFilter,
        nameNav,
        addItemFilterSearch,

        fetchList,
        tableColumns,
        perPage,
        currentPage,
        totalList,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,
        refetchData,
        formatDateAssigned,

        tenantOptions,
        tenantFilter
    }
  }
}
</script>